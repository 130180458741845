// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import getAuth function
import { getFirestore } from 'firebase/firestore'; // Import getFirestore function
import {
    getFunctions,
} from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBYxhzRhErVef9OLb5waNELjobk7ccaHok",
    authDomain: "platinummatka.firebaseapp.com",
    databaseURL: "https://platinummatka-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "platinummatka",
    storageBucket: "platinummatka.appspot.com",
    messagingSenderId: "596893258609",
    appId: "1:596893258609:web:4e64ef97601a4cb0aadcd0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const functions = getFunctions(app, 'asia-south1'); // Specify the region here

// Get the authentication instance
export const auth = getAuth(app);


export const db = getFirestore(app); // Initialize the Firestore database instance


import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import {collection, getDocs, updateDoc, doc, setDoc, getDoc, query, where, addDoc} from 'firebase/firestore';
import 'react-datepicker/dist/react-datepicker.css';
import { db } from '../firebase/firebase';

function DelhiPatternPage({ userData }) {
    const [draws, setDraws] = useState([]);
    const [selectedDraw, setSelectedDraw] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedNumber, setSelectedNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');


    const resetPageStates = () => {
        setSelectedDraw('');
        setSelectedNumber('');
        setErrorMessage('');
    };


    const fetchDraws = async () => {

        try {
            const querySnapshot = await getDocs(collection(db, 'delhi-pattern-draws'));

            let drawsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));


            if (!userData.superAdmin) {
                const userDraws = userData['delhi-pattern'] || [];
                // map drawsData to remove spaces from drawName
                for (let i = 0; i < drawsData.length; i++) {
                    drawsData[i].id = drawsData[i].id.replace(/\s/g, ' ');
                }

                drawsData = drawsData.filter(draw => userDraws.includes(draw.id));
            }

            setDraws(drawsData);

        } catch (error) {
            console.error('Error fetching draws:', error);
        }
    };

    useEffect(() => {
        fetchDraws();
    }, [userData]);

    const handleModifyNumber = async () => {
        if (!selectedDraw || !selectedNumber || selectedNumber.length > 2) {
            setErrorMessage('Invalid input. Please select a draw, enter a number (up to 2 digits), and try again.');
            return;
        }


        try {
            setIsLoading(true);

            const dateConverted = selectedDate.toLocaleDateString('en-GB');

            console.log(dateConverted)

            const allDrawsRef = collection(db, 'all-draws-beta');

            const queryRef = query(allDrawsRef, where('date', '==', dateConverted), where('idDrawName', '==', selectedDraw));
            const querySnapshot = await getDocs(queryRef);

            const existingDraw = querySnapshot.docs.map(doc => doc.data());

            const currentDraw = draws.find(draw => draw.id === selectedDraw)

            if (!existingDraw.length > 0) {
                const dataToCreate = {
                    idDrawName: selectedDraw,
                    number: selectedNumber,
                    startTime: currentDraw.startTime,
                    closingTime: currentDraw.closingTime,
                    date: dateConverted,
                    drawName: currentDraw.drawName,
                    timestamp: new Date(),
                    pattern: "delhiPattern"
                };
                await addDoc(allDrawsRef, dataToCreate);
            } else {
                const docRef = doc(db, 'all-draws-beta', querySnapshot.docs[0].id);
                await updateDoc(docRef, {
                    number: selectedNumber,
                });
            }

            setSelectedNumber('');

            resetPageStates(); // Reset states after successful modification
            setSuccessMessage(`Number successfully updated for ${currentDraw.drawName}: ${selectedNumber}`);

            fetchDraws();
        } catch (error) {
            setErrorMessage('Error modifying number:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center mx-auto mt-16 p-8 text-black">
            <h1 className="text-4xl font-bold mb-8 text-white">Delhi Pattern</h1>

            <div className="flex flex-col w-full max-w-md bg-white shadow-md rounded-lg p-6">
                <div className="mb-6">
                    <label className="text-xl font-semibold mb-2 block">Date</label>
                    <DatePicker
                        className="text-xl p-3 border border-gray-800 rounded-md w-full"
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>

                <div className="mb-6">
                    <label className="text-xl font-semibold mb-2 block">Draw</label>
                    <select
                        className="text-xl p-3 border border-gray-800 rounded-md w-full"
                        value={selectedDraw}
                        onChange={(e) => setSelectedDraw(e.target.value)}
                    >
                        <option value="">Select Draw</option>
                        {draws.map((draw) => (
                            <option key={draw.id} value={draw.id}>
                                {draw.drawName} ({draw.startTime} - {draw.closingTime})
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-6">
                    <label className="text-xl font-semibold mb-2 block">Number</label>
                    <input
                        type="text"
                        className="text-xl p-3 border border-gray-800 rounded-md w-full focus:outline-none focus:border-blue-500"
                        value={selectedNumber}
                        onChange={(e) => setSelectedNumber(e.target.value)}
                    />
                </div>

                {errorMessage && (
                    <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
                )}

                <button
                    onClick={handleModifyNumber}
                    className="mt-4 px-6 py-3 text-lg font-semibold bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out"
                >
                    Confirm Number
                </button>

                {successMessage && (
                    <p className="text-green-500 text-sm mt-4">{successMessage}</p>
                )}
            </div>
        </div>
    );
}

export default DelhiPatternPage;

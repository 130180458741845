import React, { useState } from 'react';
import { updatePassword } from 'firebase/auth';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../firebase/firebase';


function ChangePasswordPage() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const reauthenticateUser = async (email, password) => {
        const credentials = signInWithEmailAndPassword(auth, email, password);
        return credentials;
    };

    const handleChangePassword = async () => {
        try {
            setIsLoading(true);

            if (newPassword !== confirmPassword) {
                setErrorMessage('New password and confirm password must match.');
                return;
            }

            const user = auth.currentUser;
            if (!user) {
                setErrorMessage('User not authenticated.');
                return;
            }

            // Re-authenticate user
            try {
                await reauthenticateUser(user.email, currentPassword);
            } catch (error) {
                setErrorMessage('Failed to re-authenticate. Make sure your current password is correct.');
                return;
            }

            // Re-authentication successful, update the password
            try {
                await updatePassword(user, newPassword);
                setErrorMessage('');
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } catch (error) {
                setErrorMessage('Failed to change password.');
            }

        } catch (error) {
            console.error('Error changing password:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col flex-auto items-center justify-center">
            <h1 className="text-5xl mb-4">Change Password</h1>
            <div className="flex flex-col space-y-4">
                <div className="flex flex-col">
                    <label className="text-4xl font-bold mb-2">Current Password</label>
                    <input
                        type="password"
                        className="border text-4xl text-black border-gray-400 p-2"
                        value={currentPassword}
                        onChange={e => setCurrentPassword(e.target.value)}
                    />
                </div>
                <div className="flex flex-col">
                    <label className="text-4xl font-bold mb-2">New Password</label>
                    <input
                        type="password"
                        className="border text-4xl text-black border-gray-400 p-2"
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                    />
                </div>
                <div className="flex flex-col">
                    <label className="text-4xl font-bold mb-2">Confirm Password</label>
                    <input
                        type="password"
                        className={`border text-4xl text-black border-gray-400 p-2 ${errorMessage ? 'border-red-500' : ''}`}
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    {errorMessage && <p className="text-red-500 mt-1">{errorMessage}</p>}
                </div>
                <button
                    onClick={handleChangePassword}
                    className="px-4 py-2 text-4xl bg-blue-500 text-white rounded hover:bg-blue-600"
                    disabled={isLoading}
                >
                    Change Password
                </button>
            </div>
        </div>
    );

}

export default ChangePasswordPage;

import React, {useEffect, useState} from 'react';
import {collection, deleteDoc, doc, getDoc, setDoc, updateDoc} from "firebase/firestore";
import {db} from "../../firebase/firebase";
import {useSite} from "../../siteContext";
import refGetterBasedOnSite from "../../utils/RefGetterBasedOnSite";

const LuckyDrawPage = () => {

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [luckyNumbersList, setLuckyNumbersList] = useState([]);
    const {site} = useSite()

    const dbRef = refGetterBasedOnSite(site).luckyNumberRef


    const fetchDraws = async () => {
        try {
            const collectionRefDraw = doc(db, 'config', site === "android" ? "config" : site);
            const snapshot = await getDoc(collectionRefDraw);

            console.log(snapshot.data())

            if (snapshot.exists()){
                const configData = snapshot.data();
                const luckyNumbersObject = configData['luckyNumbers'] || {};
                const luckyDrawsArray = Object.entries(luckyNumbersObject).map(([title, content]) => ({title, content}));
                setLuckyNumbersList(luckyDrawsArray);
            }
        } catch (error) {
            console.error('Error fetching news: ', error);
        }
    };

    const handleAddDraws = async () => {
        try {
            const configDocRef = doc(db, 'config', site === "android" ? "config" : site);
            const luckDrawNumbersToAdd = {[title]: content};
            await setDoc(configDocRef, {
                'luckyNumbers': luckDrawNumbersToAdd
            }, { merge: true });
            console.log('WhatsApp numbers added to configuration successfully');
        } catch (error) {
            console.error('Error adding WhatsApp numbers to configuration:', error);
        }
        setTitle('');
        setContent('');
        await fetchDraws()
    };

    const handleDeleteNumbers = async (title) => {
        try {
            const configDocRef = doc(db, 'config', site === "android" ? "config" : site);
            const configDocSnapshot = await getDoc(configDocRef);

            if (configDocSnapshot.exists()) {
                const configData = configDocSnapshot.data();
                const luckyNumbersObject = configData['luckyNumbers'] || {};

                // Delete the lucky number with the specified title
                if (luckyNumbersObject[title]) {
                    delete luckyNumbersObject[title];

                    // Update Firestore document with the modified lucky numbers list
                    await updateDoc(configDocRef, {
                        'luckyNumbers': luckyNumbersObject
                    });

                    // Update the state with the new list without the removed number
                    const updatedNumbersList = Object.entries(luckyNumbersObject).map(([title, content]) => ({ title, content }));
                    setLuckyNumbersList(updatedNumbersList);

                    console.log('Lucky number removed from configuration successfully');
                } else {
                    console.error('Title not found:', title);
                }
            } else {
                console.log('Document does not exist');
            }
        } catch (error) {
            console.error('Error removing lucky number from configuration:', error);
        }
    };

    useEffect(() => {
        fetchDraws()
    }, []);

    useEffect(() => {
        fetchDraws()
    }, [site]);


    return (
        <div
            className="flex flex-col items-center justify-center mx-auto mt-16 p-8 max-w-3xl bg-white shadow-lg rounded-lg ">
            <h1 className="sm:text-4xl mb-8 font-extrabold text-gray-800">Add Lucky Numbers <span class={"capitalize"}>{site}</span></h1>

            <div className="flex flex-col space-y-6 w-full">
                <div className="flex flex-col">
                    <label className="text-lg font-semibold text-gray-600 mb-2">Title</label>
                    <input
                        type="text"
                        placeholder="Enter News Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="border border-gray-300 p-3 w-full text-gray-800 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-lg font-semibold text-gray-600">Lucky Numbers</label>
                    <textarea
                        placeholder="Enter News Content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className="border border-gray-300 p-3 w-full h-16 text-gray-800 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none resize-none"
                    />
                </div>

                <button
                    onClick={handleAddDraws}
                    className="w-full py-3 bg-blue-500 text-white font-semibold rounded-lg shadow hover:bg-blue-600 transition duration-300"
                >
                    Add Lucky Numbers
                </button>
            </div>

            <div className="mt-12 w-full">
                <h2 className="text-4xl mb-6 font-bold text-gray-800">Luck Numbers</h2>
                <div className="grid sm:grid-cols-2 gap-6">
                    { luckyNumbersList.map((luckyNumber) => (
                        <div key={luckyNumber.id} className={"border border-zinc-400 rounded-lg p-4"}>
                            <div className={"text-3xl font-bold text-center text-black"}>{luckyNumber.title}</div>
                            <div className={"p-4"}>
                                <div className={"text-center text-3xl font-bold text-black border-2 rounded-2xl border-zinc-400"}>{luckyNumber.content}</div>

                            </div>
                            <button
                                onClick={() => handleDeleteNumbers(luckyNumber.title)}
                                className="w-full py-2 bg-red-500 text-white font-semibold rounded-lg shadow hover:bg-red-600 transition duration-300"
                            >
                                Delete
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};


export default LuckyDrawPage;
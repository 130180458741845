import React, { useState } from 'react';

const LoginPage = ({ onLogin, errorMessage }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Perform your login logic here
            // Example: await loginFunction(email, password);
            await onLogin(email, password);

            // If successful, reset form and clear error message
            setEmail('');
            setPassword('');
        } catch (error) {
            // If there's an error, set the error message
        }
    };

    return (
        <div className="flex-1 flex items-center justify-center">
            <form onSubmit={handleSubmit} className="p-4 bg-white shadow-md rounded-md">
                <h2 className="text-xl font-semibold mb-4">Login</h2>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 mb-2 rounded"
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-2 mb-2 rounded"
                />
                {errorMessage && <p className="text-red-500 mb-2">{errorMessage}</p>}
                <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
                    Login
                </button>
            </form>
        </div>
    );
};

export default LoginPage;

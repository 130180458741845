import {db} from "../../firebase/firebase";
import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';

function DrawsOnAndroid() {
    const [delhiDraws, setDelhiDraws] = useState([]);
    const [mumbaiDraws, setMumbaiDraws] = useState([]);
    const [selectedDelhiDraw, setSelectedDelhiDraw] = useState('');
    const [selectedMumbaiDraw, setSelectedMumbaiDraw] = useState('');
    const [selectedMumbaiDrawId, setSelectedMumbaiDrawId] = useState(null);
    const [selectedDelhiDrawId, setSelectedDelhiDrawId] = useState(null);

    const [topDrawsMobile, setTopDrawsMobile] = useState([]);
    const [holiday, setHoliday] = useState(false);
    const [holidayMessage, setHolidayMessage] = useState('');
    const [topDrawsToFetch, setTopDrawsToFetch] = useState([]);

    useEffect(() => {
        fetchDelhiDraws();
        fetchMumbaiDraws();
        getConfig();
    }, []);

    const fetchDelhiDraws = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'delhi-pattern-draws'));
            const drawsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setDelhiDraws(drawsData);
        } catch (error) {
            console.error('Error fetching Delhi draws:', error);
        }
    };

    const fetchMumbaiDraws = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'mumbai-pattern-draws'));
            const drawsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setMumbaiDraws(drawsData);
        } catch (error) {
            console.error('Error fetching Mumbai draws:', error);
        }
    };

    const getConfig = async () => {
        const configDocRef = doc(db, 'config', 'config');
        const configDocSnapshot = await getDoc(configDocRef);

        if (configDocSnapshot.exists()) {
            const configData = configDocSnapshot.data();
            const topDrawsMobileArray = configData['top-draws-mobile'] || [];

            setHoliday(configData['holiday']);
            setHolidayMessage(configData['holiday-message']);
            setTopDrawsToFetch(topDrawsMobileArray);
            // Now you have the top-draws-mobile array
        } else {
            console.log('Document does not exist');
        }
    };

    const addToConfig = async () => {
        try {
            const configDocRef = doc(db, 'config', 'config');
            const drawsToAdd = [];
            if (selectedDelhiDrawId) {
                drawsToAdd.push(selectedDelhiDrawId);
            }
            if (selectedMumbaiDrawId) {
                drawsToAdd.push(selectedMumbaiDrawId);
            }
            await updateDoc(configDocRef, {
                'top-draws-mobile': [...topDrawsToFetch, ...drawsToAdd]
            });
            console.log('Draw IDs added to configuration successfully');

            // Refetch the new list of draws
            await getConfig();
        } catch (error) {
            console.error('Error adding draw IDs to configuration:', error);
        }

        setSelectedMumbaiDrawId(null);
        setSelectedDelhiDraw("");
        setSelectedMumbaiDraw("");
        setSelectedDelhiDrawId(null);
    };

    const removeFromConfig = async (drawIdToRemove) => {
        try {
            const configDocRef = doc(db, 'config', 'config');
            const updatedDraws = topDrawsToFetch.filter(drawId => drawId !== drawIdToRemove);
            await updateDoc(configDocRef, {
                'top-draws-mobile': updatedDraws
            });
            console.log('Draw ID removed from configuration successfully');

            // Refetch the new list of draws
            await getConfig();
        } catch (error) {
            console.error('Error removing draw ID from configuration:', error);
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="mb-8">
                <h2 className="text-3xl font-bold mb-4">Add draws to show on Android</h2>
                <div className="w-full max-w-md space-y-4">
                    <h2 className="text-xl font-bold mb-2">Select Delhi Draw</h2>
                    <div className="mb-4">
                        <label htmlFor="delhiDraw" className="text-lg font-semibold mb-2">Draw</label>
                        <select
                            id="delhiDraw"
                            className="border p-2 rounded-md w-full text-black focus:outline-none focus:border-blue-500"
                            value={selectedDelhiDraw}
                            onChange={(e) => {
                                const selectedOption = e.target.options[e.target.selectedIndex];
                                const selectedId = selectedOption.getAttribute('data-id');
                                setSelectedDelhiDrawId(selectedId);
                                setSelectedDelhiDraw(e.target.value);
                            }}
                        >
                            <option value="">Select Draw</option>
                            {delhiDraws.map((draw) => (
                                <option key={draw.id} value={draw.id} data-id={draw.id}>
                                    {draw.drawName} ({draw.startTime} - {draw.closingTime})
                                </option>
                            ))}
                        </select>
                    </div>
                    <h2 className="text-xl font-bold mb-2 ">Select Mumbai Draw</h2>
                    <div className="mb-4">
                        <label htmlFor="mumbaiDraw" className="text-lg font-semibold mb-2">Draw</label>
                        <select
                            id="mumbaiDraw"
                            className="border p-2 rounded-md w-full text-black focus:outline-none focus:border-blue-500"
                            value={selectedMumbaiDraw}
                            onChange={(e) => {
                                const selectedOption = e.target.options[e.target.selectedIndex];
                                const selectedId = selectedOption.getAttribute('data-id');
                                setSelectedMumbaiDrawId(selectedId);
                                setSelectedMumbaiDraw(e.target.value);
                            }}
                        >
                            <option value="">Select Draw</option>
                            {mumbaiDraws.map((draw) => (
                                <option key={draw.id} value={draw.id} data-id={draw.id}>
                                    {draw.drawName} ({draw.startTime} - {draw.closingTime})
                                </option>
                            ))}
                        </select>
                    </div>
                    <button
                        onClick={addToConfig}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-6 rounded">
                        Add to Config
                    </button>
                </div>
            </div>
            <div className="mt-8">
                <h2 className="text-xl font-bold mb-2">Top Draws Mobile</h2>
                <table className="table-auto">
                    <thead>
                    <tr>
                        <th className="px-4 py-2">Draw ID</th>
                        <th className="px-4 py-2">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {topDrawsToFetch.map(drawId => (
                        <tr key={drawId}>
                            <td className="border px-4 py-2">{drawId}</td>
                            <td className="border px-4 py-2">
                                <button
                                    onClick={() => removeFromConfig(drawId)}
                                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                                >
                                    Remove
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );



}

export default DrawsOnAndroid;


import React, { useState } from 'react';
import {collection, doc, setDoc} from 'firebase/firestore';
import 'react-datepicker/dist/react-datepicker.css';
import { db } from '../../firebase/firebase';

function CreateDrawsPage() {
    const [drawName, setDrawName] = useState('');
    const [startTime, setStartTime] = useState(new Date());
    const [closingTime, setClosingTime] = useState(new Date());
    const [selectedPattern, setSelectedPattern] = useState('');

    const handleAddDraw = async () => {

        try {
            const drawNameWithOutSpace = drawName.replace(/\s+/g, '-').toLowerCase();

            const drawData = {
                startTime,
                closingTime,
                selectedPattern,
                drawName,
                id: drawNameWithOutSpace,
            };


            const collectionRefDraws = selectedPattern + '-draws';
            const docRef = doc(collection(db, collectionRefDraws), drawNameWithOutSpace);

            await setDoc(docRef, drawData);

            // Clear form fields after adding the draw
            setStartTime(new Date());
            setClosingTime(new Date());
            setSelectedPattern('');
        } catch (error) {
            console.error('Error adding draw: ', error);
        }
    };

//
//
//
// // Helper function to check if a collection exists
//     const existsCollection = async (db, collectionRef) => {
//         try {
//             const querySnapshot = await getDocs(collection(db, collectionRef));
//             return !querySnapshot.empty;
//         } catch (error) {
//             console.error('Error checking collection existence: ', error);
//             return false;
//         }
//     };


    return (
        <div className="flex flex-col items-center justify-center mx-auto mt-16 p-8">
            <h1 className="text-5xl mb-6">Create Draws Page</h1>

            <div className="w-full max-w-md space-y-4">
                <div>
                    <label className="text-2xl font-semibold mb-2">Start Time</label>
                    <input
                        type="time"
                        className="border p-2 text-black rounded-md w-full focus:outline-none focus:border-blue-500"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                    />
                </div>

                <div>
                    <label className="text-2xl  font-semibold mb-2">Closing Time</label>
                    <input
                        type="time"
                        className="border p-2 rounded-md w-full text-black focus:outline-none focus:border-blue-500"
                        value={closingTime}
                        onChange={(e) => setClosingTime(e.target.value)}
                    />
                </div>

                <div>
                    <label className="text-2xl font-semibold mb-2">Draw Name</label>
                    <input
                        type="text"
                        className="border p-2 rounded-md w-full text-black focus:outline-none focus:border-blue-500"
                        placeholder="Enter Draw Name"
                        onChange={(e) => setDrawName(e.target.value)}
                    />
                </div>

                <div>
                    <label className="text-2xl font-semibold mb-2">Pattern</label>
                    <select
                        className="border p-2 rounded-md text-black w-full focus:outline-none focus:border-blue-500"
                        value={selectedPattern}
                        onChange={(e) => setSelectedPattern(e.target.value)}
                    >
                        <option value="">Select Pattern</option>
                        <option value="delhi-pattern">Delhi Pattern</option>
                        <option value="mumbai-pattern">Mumbai Pattern</option>
                    </select>
                </div>

                <button
                    onClick={handleAddDraw}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out"
                >
                    Add Draw
                </button>
            </div>
        </div>
    );


}

export default CreateDrawsPage;

import React, { createContext, useContext, useState } from 'react';

const SiteContext = createContext();

export const useSite = () => {
    return useContext(SiteContext);
};

export const SiteProvider = ({ children }) => {
    const [site, setSite] = useState("dpanna-main");

    return (
        <SiteContext.Provider value={{ site, setSite }}>
            {children}
        </SiteContext.Provider>
    );
};

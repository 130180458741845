import React, { useEffect, useState } from 'react';
import { collection, deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useSite } from "../../siteContext";
import refGetterBasedOnSite from "../../utils/RefGetterBasedOnSite";

const MultipleNumbersPatti = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [luckyNumbersList, setLuckyNumbersList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { site } = useSite();

    const dbRef = refGetterBasedOnSite(site).luckyNumberRef;

    const fetchDraws = async () => {
        try {
            const collectionRefDraw = doc(db, 'config', site === "android" ? "config" : site);
            const snapshot = await getDoc(collectionRefDraw);

            console.log(snapshot.data())

            if (snapshot.exists()) {
                const configData = snapshot.data();
                const luckyNumbersObject = configData['multipleNumbers'] || {};
                const luckyDrawsArray = Object.entries(luckyNumbersObject).map(([key, content]) => ({ key, content }));
                setLuckyNumbersList(luckyDrawsArray);
            }
        } catch (error) {
            console.error('Error fetching news: ', error);
        }
    };

    const handleAddDraws = async () => {
        setIsLoading(true);
        try {
            const configDocRef = doc(db, 'config', site === "android" ? "config" : site);
            const luckDrawNumbersToAdd = { [title]: content };
            await setDoc(configDocRef, {
                'multipleNumbers': luckDrawNumbersToAdd
            }, { merge: true });
            console.log('WhatsApp numbers added to configuration successfully');
            setTitle('');
            setContent('');
            await fetchDraws();
        } catch (error) {
            console.error('Error adding WhatsApp numbers to configuration:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteNumbers = async (key) => {
        setIsLoading(true);
        try {
            const configDocRef = doc(db, 'config', site === "android" ? "config" : site);
            const configDocSnapshot = await getDoc(configDocRef);

            if (configDocSnapshot.exists()) {
                const configData = configDocSnapshot.data();
                const luckyNumbersObject = configData['multipleNumbers'] || {};

                if (luckyNumbersObject[key]) {
                    delete luckyNumbersObject[key];

                    await updateDoc(configDocRef, {
                        'multipleNumbers': luckyNumbersObject
                    });

                    const updatedNumbersList = Object.entries(luckyNumbersObject).map(([key, content]) => ({ key, content }));
                    setLuckyNumbersList(updatedNumbersList);

                    console.log('Lucky number removed from configuration successfully');
                } else {
                    console.error('Key not found:', key);
                }
            } else {
                console.log('Document does not exist');
            }
        } catch (error) {
            console.error('Error removing lucky number from configuration:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDraws()
    }, []);

    useEffect(() => {
        fetchDraws()
    }, [site]);

    return (
        <div className="flex flex-col items-center justify-center mx-auto mt-16 p-8 max-w-3xl bg-white shadow-lg rounded-lg ">
            <h1 className="sm:text-4xl mb-8 font-extrabold text-gray-800">Add 3x3 Patti Numbers <span className="capitalize">{site}</span></h1>

            <div className="flex flex-col space-y-6 w-full">
                <div className="flex flex-col">
                    <label className="text-lg font-semibold text-gray-600 mb-2">Title</label>
                    <input
                        type="text"
                        placeholder="Enter News Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="border border-gray-300 p-3 w-full text-gray-800 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-lg font-semibold text-gray-600">3x3 Patti Numbers</label>
                    <textarea
                        placeholder="Enter 3x3 Patti Numbers"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className="border border-gray-300 p-3 w-full h-16 text-gray-800 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none resize-none"
                    />
                </div>

                <button
                    onClick={handleAddDraws}
                    disabled={isLoading}
                    className={`w-full py-3 ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'} text-white font-semibold rounded-lg shadow transition duration-300`}
                >
                    {isLoading ? 'Adding...' : 'Add Lucky Numbers'}
                </button>
            </div>

            <div className="mt-12 w-full">
                <h2 className="text-4xl mb-6 font-bold text-gray-800">3x3 Patti Numbers</h2>
                <div className="grid grid-cols-2 gap-6">
                    {luckyNumbersList.map((luckyNumber) => (
                        <div key={luckyNumber.key} className="border-2 border-yellow-400 rounded-lg p-4 text-black">
                            <div className="text-3xl font-bold text-center mb-4">{luckyNumber.key}</div>
                            <div className="grid grid-cols-3 gap-4 mb-4">
                                {luckyNumber.content.split(',').map((number, index) => (
                                    <div key={index} className="text-center text-3xl font-bold">{number.trim()}</div>
                                ))}
                            </div>
                            <button
                                onClick={() => handleDeleteNumbers(luckyNumber.key)}
                                disabled={isLoading}
                                className={`w-full py-2 ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500 hover:bg-red-600'} text-white font-semibold rounded-lg shadow transition duration-300`}
                            >
                                {isLoading ? 'Deleting...' : 'Delete'}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default MultipleNumbersPatti;
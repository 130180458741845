import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase/firebase';
import { collection, getDocs, updateDoc, deleteDoc, doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { useSite } from "../../siteContext";
import refGetterBasedOnSite from "../../utils/RefGetterBasedOnSite";
import { Timestamp } from 'firebase/firestore';

function AddNewsPage() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [newsList, setNewsList] = useState([]);
    const fileInputRef = useRef(null);

    const { site } = useSite()

    const storage = getStorage();
    const storageRef = ref(storage);
    const newsRef = refGetterBasedOnSite(site).newsRef;

    useEffect(() => {
        fetchNews();
    }, []);

    useEffect(() => {
        fetchNews();
    }, [site]);

    const fetchNews = async () => {
        try {
            const collectionRefNews = collection(db, newsRef);
            const snapshot = await getDocs(collectionRefNews);
            const newsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setNewsList(newsData);
        } catch (error) {
            console.error('Error fetching news: ', error);
        }
    };

    const handleDeleteNews = async (id) => {
        try {
            const docRef = doc(db, newsRef, id);
            await deleteDoc(docRef);
            fetchNews();
        } catch (error) {
            console.error('Error deleting news: ', error);
        }
    };

    const handleAddNews = async () => {
        try {
            let imageUrl = null;

            if (image) {
                const fileName = image.name;
                const fileExtension = fileName.split('.').pop();
                const uniqueID = `${uuidv4()}.${fileExtension}`;
                const imagesRef = ref(storage, `news/${uniqueID}`);
                imageUrl = `news/${uniqueID}`;

                await uploadBytes(imagesRef, image).then(() => {
                    console.log('Uploaded a blob or file!');
                });
            }

            const newsData = {
                title,
                content,
                timestamp: Timestamp.now(), // Use Firestore Timestamp
                ...(imageUrl && { imageUrl }),
            };

            const collectionRefNews = collection(db, newsRef);
            await setDoc(doc(collectionRefNews), newsData);

            setTitle('');
            setContent('');
            setImage(null);
            fileInputRef.current.value = ''; // Reset file input
            fetchNews();
        } catch (error) {
            console.error('Error adding news: ', error);
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center mx-auto mt-16 p-8 max-w-4xl bg-white shadow-lg rounded-2xl">
            <h1 className="text-5xl mb-8 font-extrabold text-gray-800">Add News</h1>

            <div className="flex flex-col space-y-6 w-full">
                <div className="flex flex-col">
                    <label className="text-lg font-semibold text-gray-600 mb-2">News Title</label>
                    <input
                        type="text"
                        placeholder="Enter News Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="border border-gray-300 p-3 w-full text-gray-800 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-lg font-semibold text-gray-600 mb-2">News Content</label>
                    <textarea
                        placeholder="Enter News Content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className="border border-gray-300 p-3 w-full h-32 text-gray-800 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none resize-none"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-lg font-semibold text-gray-600 mb-2">News Image</label>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleImageChange}
                        className="border border-gray-300 p-3 w-full text-gray-800 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none"
                    />
                </div>

                <button
                    onClick={handleAddNews}
                    className="w-full py-3 bg-blue-500 text-white font-semibold rounded-lg shadow hover:bg-blue-600 transition duration-300"
                >
                    Add News
                </button>
            </div>

            {newsList.length > 0 &&
                <div className="mt-12 w-full">
                    <h2 className="text-4xl mb-6 font-bold text-gray-800">All News</h2>
                    <div className="grid grid-cols-2 gap-6">
                        {newsList.map((news) => (
                            <div key={news.id} className="bg-white border border-gray-200 rounded-lg shadow-md p-4">
                                <h3 className="text-3xl font-bold text-gray-800 mb-2">{news.title}</h3>
                                <p className="text-gray-600 text-xl mb-4">{news.content}</p>
                                {news.imageUrl && (
                                    <img
                                        src={`https://ik.imagekit.io/emqeuwvea1/${news.imageUrl}`}
                                        alt="News"
                                        className="h-96 w-full object-contain rounded-md mb-4"
                                    />
                                )}
                                {news.timestamp && news.timestamp.seconds && (
                                    <p className="text-lg text-gray-500 mb-4">{new Date(news.timestamp.seconds * 1000).toLocaleString()}</p>
                                )}
                                <button
                                    onClick={() => handleDeleteNews(news.id)}
                                    className="w-full py-2 bg-red-500 text-white font-semibold rounded-lg shadow hover:bg-red-600 transition duration-300"
                                >
                                    Delete
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
}

export default AddNewsPage;
import { useState, useEffect } from 'react';
import {doc, getDoc, setDoc, updateDoc} from 'firebase/firestore';
import {db} from "../../firebase/firebase";
import {useSite} from "../../siteContext";
import RefGetterBasedOnSite from "../../utils/RefGetterBasedOnSite";


function WhatsAppNumbersAddOnWeb() {
    const [selectedWhatsAppNumber, setSelectedWhatsAppNumber] = useState('');
    const [selectedWhatsAppName, setSelectedWhatsAppName] = useState('');
    const [whatsappNumbersList, setWhatsAppNumbersList] = useState([]);
    const { site } = useSite();

    const ref = site

    useEffect(() => {
        getConfig();
    }, [site]);

    const getConfig = async () => {
        console.log(ref)
        const configDocRef = doc(db, 'config', ref);
        const configDocSnapshot = await getDoc(configDocRef);

        console.log(configDocSnapshot.data());

        if (configDocSnapshot.exists()) {
            const configData = configDocSnapshot.data();
            const whatsappNumbersObject = configData['whatsAppNumbers'] || {};

            const whatsappNumbersArray = Object.entries(whatsappNumbersObject).map(([name, number]) => ({ name, number }));
            setWhatsAppNumbersList(whatsappNumbersArray);
        } else {
            console.log('Document does not exist');
        }
    };

    const addToConfig = async () => {
        try {
            const configDocRef = doc(db, 'config', ref); // Reference to the document
            const numberToAdd = { [selectedWhatsAppName]: selectedWhatsAppNumber }; // New WhatsApp number as key-value pair
            await setDoc(configDocRef, {
                'whatsAppNumbers': numberToAdd // Set the document with the WhatsApp number
            }, { merge: true }); // Merge with existing data if the document already exists
            console.log('WhatsApp numbers added to configuration successfully');
        } catch (error) {
            console.error('Error adding WhatsApp numbers to configuration:', error);
        }

        setSelectedWhatsAppName('');
        setSelectedWhatsAppNumber('');
        await getConfig()
    };

    const removeWhatsAppNumber = async (index) => {
        try {
            const configDocRef = doc(db, 'config', ref); // Reference to the document
            const configDocSnapshot = await getDoc(configDocRef); // Get the document snapshot

            if (configDocSnapshot.exists()) {
                const configData = configDocSnapshot.data();
                const whatsappNumbersObject = configData['whatsAppNumbers'] || {};

                // Convert object to array of key-value pairs
                const whatsappNumbersArray = Object.entries(whatsappNumbersObject).map(([name, number]) => ({ name, number }));

                // Remove the WhatsApp number at the specified index
                const numberToRemove = whatsappNumbersArray[index];
                delete whatsappNumbersObject[numberToRemove.name];

                // Update Firestore document with the modified WhatsApp numbers list
                await updateDoc(configDocRef, {
                    'whatsAppNumbers': whatsappNumbersObject
                });

                // Update the state with the new list without the removed number
                const updatedNumbersList = whatsappNumbersArray.filter((_, i) => i !== index);
                setWhatsAppNumbersList(updatedNumbersList);

                console.log('WhatsApp number removed from configuration successfully');
            } else {
                console.log('Document does not exist');
            }
        } catch (error) {
            console.error('Error removing WhatsApp number from configuration:', error);
        }
    };


    return (
        <div className="container mx-auto px-4 py-8">
            <div className="mb-8">
                <h2 className="text-3xl font-bold mb-4">WhatsApp Number <span className={"capitalize"}>{site}</span>
                </h2>
                <div className="w-full max-w-md space-y-4">
                    <label htmlFor="whatsappName" className="text-lg font-semibold mb-2">Name</label>
                    <input
                        id="whatsappName"
                        type="text"
                        className="border p-2 rounded-md w-full text-black focus:outline-none focus:border-blue-500"
                        value={selectedWhatsAppName}
                        onChange={(e) => setSelectedWhatsAppName(e.target.value)}
                        placeholder="Enter Name"
                    />
                </div>
                <div className="w-full max-w-md space-y-4">
                    <label htmlFor="whatsappNumber" className="text-lg font-semibold mb-2">Number</label>
                    <input
                        id="whatsappNumber"
                        type="text"
                        className="border p-2 rounded-md w-full text-black focus:outline-none focus:border-blue-500"
                        value={selectedWhatsAppNumber}
                        onChange={(e) => setSelectedWhatsAppNumber(e.target.value)}
                        placeholder="Enter WhatsApp Number"
                    />
                </div>
                <button onClick={addToConfig}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-6 rounded">
                    Add to Config
                </button>
            </div>
            <div>
                <h2 className="text-3xl font-bold mb-4">WhatsApp Numbers Configuration</h2>
                <table className="table-auto">
                    <thead>
                    <tr>
                        <th className="px-4 py-2">Name</th>
                        <th className="px-4 py-2">Number</th>
                        <th className="px-4 py-2">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {whatsappNumbersList.map((number, index) => (
                        <tr key={index}>
                            <td className="border px-4 py-2">{number.name}</td>
                            <td className="border px-4 py-2">{number.number}</td>
                            <td className="border px-4 py-2">
                                <button onClick={() => removeWhatsAppNumber(index)} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

}

export default WhatsAppNumbersAddOnWeb;

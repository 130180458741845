const RefGetterBasedOnSite = (site ) => {

    let references;

    switch (site) {
        case "dpanna-main":
            references = {
                newsRef: "daily-news-dpanna-main",
                luckyNumberRef: "lucky-numbers-dpanna-main",
                drawsToShowRef: "dpanna-main"
            }
            break;
        case "dpanna-golden-news":
            references = {
                newsRef: "daily-news-dpanna-golden-news",
                luckyNumberRef: "lucky-numbers-dpanna-golden-news",
                drawsToShowRef: "dpanna-golden-news"
            }
            break;
        case "android":
            references = {
                newsRef: "daily-news-android",
                drawsToShowRef: "android"
            }
            break;
        default:
            return null;
    }

    return references;
};

export default RefGetterBasedOnSite;
